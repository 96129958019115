import { useQuery } from '@tanstack/react-query';
import { configApi } from '../../configApi';

export interface IProofLocationProps {
  registration_code: number;
  first_vacancy: string;
  second_vacancy: string;
  candidate_name: string;
  cpf: string;
  document_number: string;
  date_of_birth: string;
  candidate_address: string;
  cellphone: string;
  test_date: string;
  test_hour: string;
  gate_opening_time: string;
  gate_closing_time: string;
  test_site_name: string;
  test_site_address: string;
  test_room_name: string;
  candidate_registration_card: string;
  document_type: string;
  notice_name: string;
  details: string;
}

const GetProofLocation = async (registrationId: string) => {
  const response = await configApi.get<IProofLocationProps>(`registration/test-admission-card/`, {
    params: {
      registration_id: registrationId,
    },
  });
  return response.data;
};

export const useGetProofLocation = (registrationId: string) => {
  return useQuery({
    queryKey: ['Get_Proof_Location', registrationId],
    queryFn: () => GetProofLocation(registrationId),
    refetchOnWindowFocus: false,
  });
};
