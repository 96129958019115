import { Box, Spinner, Stack } from '@chakra-ui/react';
import MainCard from '../../../../components/MainCard/Index';
import { TextS, TextXS, TextXXS } from '../../../../components/Text/Index';
import ProofLocationButton from '../../../../components/PDFs/DownloadPDFButton/proofLocationButton';
import { useGetProofLocation } from '../../../../services/hooks/ProofLocation/useGetProofLocation';

const ProofLocationCandidateArea: React.FC = () => {
  const registrationId = sessionStorage.getItem('registrationId') as string;
  const proofLocationData = useGetProofLocation(registrationId);
  const widhtLabel = ['', '', '', '', ''];
  const boxDisplay = ['', '', 'flex', 'flex', 'flex'];
  const boxGap = ['', '', '5px', '5px', '5px'];

  return (
    <MainCard title="ÁREA DO CANDIDATO " subtitle="- LOCAL DE PROVA">
      {proofLocationData?.isLoading ? (
        <Stack align="center" justify="center">
          <Spinner />
        </Stack>
      ) : (
        <>
          <Stack p={'20px'} align="center">
            <TextS fontWeight="bold">CARTÃO DE CONVOCAÇÃO DE PROVA</TextS>
            <TextS fontWeight="bold">{proofLocationData?.data?.notice_name}</TextS>
          </Stack>
          <Stack px={[2, 2, 2, 10, 10]}>
            <Stack>
              <TextXS textAlign="center" fontWeight="bold">
                DADOS DO(A) CANDIDATO(A)
              </TextXS>
              <Stack>
                <Box display={boxDisplay} gap={boxGap}>
                  <TextXS fontWeight="bold" w={widhtLabel}>
                    INSCRIÇÃO:
                  </TextXS>
                  <TextXS>{proofLocationData?.data?.registration_code}</TextXS>
                </Box>
                <Box display={boxDisplay} gap={boxGap}>
                  <TextXS fontWeight="bold" w={widhtLabel}>
                    CARGO:
                  </TextXS>
                  <TextXS>{proofLocationData?.data?.first_vacancy.toUpperCase()}</TextXS>
                </Box>
                {proofLocationData?.data?.second_vacancy && (
                  <Box display={boxDisplay} gap={boxGap}>
                    <TextXS fontWeight="bold" w={widhtLabel}>
                      SEGUNDA OPÇÃO:
                    </TextXS>
                    <TextXS>{proofLocationData?.data?.second_vacancy}</TextXS>
                  </Box>
                )}

                <Box display={boxDisplay} gap={boxGap}>
                  <TextXS fontWeight="bold" w={widhtLabel}>
                    NOME COMPLETO:
                  </TextXS>
                  <TextXS>{proofLocationData?.data?.candidate_name.toUpperCase()}</TextXS>
                </Box>
                <Box display={boxDisplay} gap={boxGap}>
                  <TextXS fontWeight="bold" w={widhtLabel}>
                    CPF:
                  </TextXS>
                  <TextXS>{proofLocationData?.data?.cpf}</TextXS>
                </Box>
                <Box display={boxDisplay} gap={boxGap}>
                  <TextXS fontWeight="bold" w={widhtLabel}>
                    DOCUMENTO:
                  </TextXS>
                  <TextXS>
                    {proofLocationData?.data?.document_type}: {proofLocationData?.data?.document_number}
                  </TextXS>
                </Box>
                <Box display={boxDisplay} gap={boxGap}>
                  <TextXS fontWeight="bold" w={widhtLabel}>
                    DATA DE NASCIMENTO:
                  </TextXS>
                  <TextXS>{proofLocationData?.data?.date_of_birth}</TextXS>
                </Box>
                <Box display={boxDisplay} gap={boxGap}>
                  <TextXS fontWeight="bold" w={widhtLabel}>
                    ENDEREÇO:
                  </TextXS>
                  <TextXS>{proofLocationData?.data?.candidate_address.toUpperCase()}</TextXS>
                </Box>
                <Box display={boxDisplay} gap={boxGap}>
                  <TextXS fontWeight="bold" w={widhtLabel}>
                    TELEFONE CELULAR:
                  </TextXS>
                  <TextXS>{proofLocationData?.data?.cellphone}</TextXS>
                </Box>
              </Stack>
            </Stack>

            <Stack>
              <TextXS textAlign="center" fontWeight="bold">
                DADOS PARA REALIZAÇÃO DA PROVA
              </TextXS>
              <Stack>
                <Box display={boxDisplay} gap={boxGap}>
                  <TextXS fontWeight="bold" w={widhtLabel}>
                    LOCAL DE PROVA:
                  </TextXS>
                  <TextXS>{proofLocationData?.data?.test_site_name}</TextXS>
                </Box>

                <Box display={boxDisplay} gap={boxGap}>
                  <TextXS fontWeight="bold" w={widhtLabel}>
                    SALA:
                  </TextXS>
                  <TextXS>
                    {proofLocationData?.data?.test_room_name}{' '}
                    <TextXXS as={'span'}>({proofLocationData?.data?.details})</TextXXS>
                  </TextXS>
                </Box>

                <Box display={boxDisplay} gap={boxGap}>
                  <TextXS fontWeight="bold" w={widhtLabel}>
                    ENDEREÇO:
                  </TextXS>
                  <TextXS>{proofLocationData?.data?.test_site_address.toUpperCase()}</TextXS>
                </Box>
                <Box display={boxDisplay} gap={boxGap}>
                  <TextXS fontWeight="bold" w={widhtLabel}>
                    DATA DA PROVA:
                  </TextXS>
                  <TextXS>{proofLocationData?.data?.test_date}</TextXS>
                </Box>
                <Box display={boxDisplay} gap={boxGap}>
                  <TextXS fontWeight="bold" w={widhtLabel}>
                    HORA DA PROVA:
                  </TextXS>
                  <TextXS>{proofLocationData?.data?.test_hour}h</TextXS>
                </Box>
                <Box display={boxDisplay} gap={boxGap}>
                  <TextXS fontWeight="bold" w={widhtLabel}>
                    ABERTURA DOS PORTÕES:
                  </TextXS>
                  <TextXS>{proofLocationData?.data?.gate_opening_time}h</TextXS>
                </Box>
                <Box display={boxDisplay} gap={boxGap}>
                  <TextXS fontWeight="bold" w={widhtLabel}>
                    FECHAMENTO DOS PORTÕES:
                  </TextXS>
                  <TextXS>{proofLocationData?.data?.gate_closing_time}h</TextXS>
                </Box>
              </Stack>
            </Stack>

            {proofLocationData?.data?.candidate_registration_card && (
              <Stack>
                <TextXS textAlign="center" fontWeight="bold">
                  INFORMAÇÕES IMPORTANTES
                </TextXS>
                <TextXS
                  dangerouslySetInnerHTML={{ __html: proofLocationData?.data?.candidate_registration_card || '' }}
                />
              </Stack>
            )}

            <Stack my={'10px'} align="center">
              <ProofLocationButton data={proofLocationData?.data} isModeViewer />
            </Stack>
          </Stack>
        </>
      )}
    </MainCard>
  );
};

export default ProofLocationCandidateArea;
