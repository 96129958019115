import { useQuery } from '@tanstack/react-query';
import { configApi } from '../../configApi';

interface IExemptions {
  id: string;
  registration_code: number;
  selective_process_id: string;
  selective_process_name: string;
  status: string;
  detail: string;
  attachments: IAttachmentProps[];
  type: string;
  exemption_confirmation: string;
}

interface IAttachmentProps {
  id: string;
  name: string;
  file: string;
  type: string;
  status: string;
  sent_by_client: boolean;
}

const RequestMyExemption = async () => {
  const response = await configApi.get<IExemptions[]>('candidate-area/candidate-exemptions/');
  return response.data;
};

export const useRequestMyExemption = () => {
  return useQuery({
    queryKey: ['RequestMyExemption'],
    queryFn: RequestMyExemption,
    refetchOnWindowFocus: false,
  });
};
