import { Avatar, Flex, Menu, MenuButton, MenuItem, MenuList, Skeleton, SkeletonCircle, Stack } from '@chakra-ui/react';
import { useContext } from 'react';
import { FaRegUserCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { ContextAuth } from '../../contexts/Authentication';
import { IoIosArrowDown } from 'react-icons/io';
import { TextXS } from '../Text/Index';

const ProfileWeb: React.FC = () => {
  const navigate = useNavigate();
  const { isAuth, signOut, candidate, loadingCandidate } = useContext(ContextAuth);
  if (isAuth) {
    return (
      <Flex align="center" gap={5} py="5px" display={['none', 'none', 'none', 'flex', 'flex']}>
        <Stack>
          {(loadingCandidate && <Skeleton w="110px" h="18px" />) || (
            <TextXS lineHeight="24px" maxW="90px" justifyContent="flex-end" textAlign="right">
              Olá, <strong>{candidate?.user?.full_name?.split(' ')[0]}</strong>
            </TextXS>
          )}
        </Stack>
        <Stack cursor="pointer">
          {(loadingCandidate && <SkeletonCircle w="60px" h="60px" />) || (
            <Avatar
              w="60px"
              h="60px"
              name={candidate?.user?.full_name}
              onClick={() => {
                navigate('/painel');
              }}
            />
          )}
        </Stack>
        <Menu>
          <MenuButton>
            <IoIosArrowDown />
          </MenuButton>
          <MenuList>
            <MenuItem
              onClick={() => {
                navigate('/painel/meus-dados');
              }}
            >
              Meus dados
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate('/painel/alterar-senha');
              }}
            >
              Alterar senha
            </MenuItem>
            <MenuItem
              onClick={() => {
                signOut();
                navigate('/');
              }}
            >
              Sair
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    );
  }

  return (
    <Stack h="100%" justifyContent="center">
      <Stack
        display={['none', 'none', 'none', 'flex', 'flex']}
        flexDir="row"
        gap="10px"
        bg="primaryColor"
        color="white"
        h="fit-content"
        p="10px"
        borderRadius="40px"
        cursor="pointer"
        alignItems="center"
        onClick={() => {
          navigate('/login');
        }}
      >
        <FaRegUserCircle size={22} />
        <TextXS pr={2}>Área do Candidato</TextXS>
      </Stack>
    </Stack>
  );
};

export default ProfileWeb;
