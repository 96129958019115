import { Box, BoxProps, Flex } from '@chakra-ui/react';
import React from 'react';
import { TextM } from '../../Text/Index';
import { FiAlertCircle } from 'react-icons/fi';

interface IOwnProps extends BoxProps {
  informationText: string;
}

const MessageForUser: React.FC<IOwnProps> = ({ informationText, ...rest }) => {
  return (
    <Box bg="blue.50" borderWidth="1px" borderColor="blue.200" borderRadius="md" p={4} {...rest}>
      <Flex flexDir={['column', 'column', 'column', 'row', 'row']} gap={2} align="center">
        <Box>
          <FiAlertCircle color="blue.700" size={30} />
        </Box>
        <TextM
          fontSize={['15px', '15px', '15px', '18px']}
          dangerouslySetInnerHTML={{ __html: informationText }}
          fontWeight="bold"
          color="blue.700"
        />
      </Flex>
    </Box>
  );
};

export default MessageForUser;
