import {
  Accordion,
  Badge,
  Button,
  Divider,
  Flex,
  Image,
  Link,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  TableContainer,
  Tabs,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  UnorderedList,
  useBreakpoint,
  useDisclosure,
} from '@chakra-ui/react';
import MainCard from '../../../../components/MainCard/Index';

import { useLocation, useNavigate } from 'react-router-dom';
import { useRequestDetailSelectiveProcess } from '../../../../services/hooks/SelectiveProcess/useRequestDetailSelectiveProcess';
import { badgeColor } from '../../../../utils/badgeFunction';
import {
  ETypeExemption,
  IDetailSelectiveProcess,
  IVacanciesProps,
} from '../../../../services/hooks/SelectiveProcess/Types';
import { useContext, useState } from 'react';
import { ContextAuth } from '../../../../contexts/Authentication';
import AccordionTemplate from '../../../../components/AccordionTemplate/Index';
import { FaUserGroup } from 'react-icons/fa6';
import pcdLogo from '../../../../assets/pcd.svg';
import { TextL, TextS, TextXS } from '../../../../components/Text/Index';
import { IoMdLink } from 'react-icons/io';
import { currencyFormatter } from '../../../../utils/ultilFunctions';
import MessageForUser from '../../../../components/pages/SelectiveProcess/MessageForUser';

const SelectiveProcessInformation: React.FC = () => {
  const navigate = useNavigate();
  const idSelectiveProcess = useLocation().pathname.split('/')[3];
  const { isAuth } = useContext(ContextAuth);
  const { data, isPending } = useRequestDetailSelectiveProcess(idSelectiveProcess);
  const [selectedVacancy, setSelectedVacancy] = useState<IVacanciesProps>();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const screenSize = useBreakpoint();
  sessionStorage.removeItem('acceptTerms');
  const handleDetail = (vacancy: IVacanciesProps) => {
    setSelectedVacancy(vacancy);
    onOpen();
  };

  const handleSubscription = (data: IDetailSelectiveProcess) => {
    if (data.display_terms_acceptance_before_registration)
      navigate(`/processo-seletivo/edital?selective_process_id=${idSelectiveProcess}&isExemption=false`);
    else if (isAuth) navigate(`/processo-seletivo/${data.id}/inscricao`);
    else navigate(`/processo-seletivo/${data.id}/validar-cpf`);
  };

  const handleExemption = (data: IDetailSelectiveProcess) => {
    if (data?.display_terms_acceptance_before_registration) {
      navigate(`/processo-seletivo/edital?selective_process_id=${idSelectiveProcess}&isExemption=true`);
      return;
    }

    if (isAuth) {
      if (data?.general_info.exemption_registration_creation_type === ETypeExemption.standby) {
        navigate(`/processo-seletivo/${data?.id}/isencao`);
      } else navigate(`/processo-seletivo/${data?.id}/inscricao/isencao`);
    } else {
      navigate(
        `/processo-seletivo/${data.id}/validar-cpf?isExemption=true&exemption_registration_creation_type=${data?.general_info.exemption_registration_creation_type}`,
      );
    }
  };

  const handleImpugnation = (data: IDetailSelectiveProcess) => {
    if (isAuth) navigate(`/painel/${data?.id}/impugnacao`);
    else navigate(`/processo-seletivo/${data?.id}/validar-cpf?impugnation=true`);
  };

  if (!idSelectiveProcess) navigate(-1);

  if (isPending) {
    return (
      <MainCard title="PROCESSO SELETIVO - " subtitle="MAIS INFORMAÇÕES">
        <Stack justify="center" align="center">
          <Spinner />
        </Stack>
      </MainCard>
    );
  }

  return (
    <MainCard title="PROCESSO SELETIVO - " subtitle="MAIS INFORMAÇÕES">
      <Flex flexDir="column" gap="30px" px={['10px', '10px', '10px', '50px', '50px']}>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          flexDir={['column', 'column', 'column', 'row', 'row']}
          overflow="hidden"
          gap={10}
        >
          <Stack color="darkGrey">
            <TextS fontWeight="medium"> {data?.selective_process_type}</TextS>
            <TextL>
              <strong>{data?.name}</strong>
            </TextL>
          </Stack>
          <Stack
            w={['100%', '100%', 'auto', 'auto', 'auto']}
            mb={['20px', '20px', '20px', '0px', '0px']}
            lineHeight="30px"
            color="darkGrey"
          >
            {data?.general_info?.open_impugnation && (
              <Button
                onClick={() => handleImpugnation(data)}
                color="white"
                bg="colorLink"
                boxShadow=" rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;"
                w="100%"
                p={2}
                fontSize="18px"
                _hover={{
                  bg: '#4fc1dd',
                }}
              >
                <TextXS px={3} w="100%" py="6px" pb={1} borderBottom="1px solid white">
                  IMPUGNAÇÃO CONTRA O EDITAL
                </TextXS>
              </Button>
            )}
            {data?.general_info.open_registration && (
              <Button
                onClick={() => handleSubscription(data)}
                color="white"
                bg="#008c21"
                boxShadow=" rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;"
                w="100%"
                p={2}
                fontSize="18px"
                _hover={{
                  bg: '#20b643',
                }}
              >
                <TextXS px={3} w="100%" py="6px" pb={1} borderBottom="1px solid white">
                  INSCRIÇÃO
                </TextXS>
              </Button>
            )}

            {data?.general_info.open_exemption && (
              <Button
                onClick={() => handleExemption(data)}
                color="white"
                bg="#671111"
                boxShadow=" rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;"
                w="100%"
                p={2}
                fontSize="18px"
                _hover={{
                  bg: '#773939',
                }}
              >
                <TextXS px={3} w="100%" py="6px" pb={1} borderBottom="1px solid white">
                  SOLICITAÇÃO DE ISENÇÃO
                </TextXS>
              </Button>
            )}
            {/* {data?.general_info.open_exemption && (
              <Button
                onClick={() => handleExemptionTest(data)}
                color="white"
                bg="#671111"
                boxShadow=" rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;"
                w="100%"
                p={2}
                fontSize="18px"
                _hover={{
                  bg: '#773939',
                }}
              >
                <TextXS px={3} w="100%" py="6px" pb={1} borderBottom="1px solid white">
                  ISENÇÃO TESTE
                </TextXS>
              </Button>
            )} */}
          </Stack>
        </Flex>

        <Stack gap="70px">
          <Accordion gap={1} flexDir="column" display="flex" allowMultiple defaultIndex={[0, 1, 2, 3]}>
            <AccordionTemplate
              border="1px solid"
              borderColor="primaryColor"
              borderRadius={6}
              title="INFORMAÇÕES GERAIS"
            >
              <Stack p={3}>
                {data?.details && <MessageForUser mb={2} informationText={data?.details || ''} />}
                <TextXS>
                  <strong>Edital:</strong> {data?.general_info.notice_number}
                </TextXS>
                {data?.registration_period && (
                  <TextXS>
                    <strong>Período de inscrição:</strong> {data?.registration_period}
                  </TextXS>
                )}
                {data?.general_info.exemption_period && (
                  <TextXS>
                    <strong>Período de isenção:</strong> {data?.general_info.exemption_period}
                  </TextXS>
                )}
                {data?.general_info.has_pcd && (
                  <Flex align="center">
                    <Image src={pcdLogo} w="24px" h="24px" />
                    <TextXS ml={2} fontWeight="bold">
                      Pessoas com deficiência - PCDs
                    </TextXS>
                  </Flex>
                )}
                {data?.general_info.has_black_or_brown && (
                  <Flex align="center">
                    <FaUserGroup size={24} />
                    <TextXS ml={2} fontWeight="bold">
                      Cotas raciais
                    </TextXS>
                  </Flex>
                )}
                <Flex mt={2} align="center" gap={2} flexDir={['column', 'column', 'row', 'row', 'row']}>
                  {data?.general_info.status.map((situation, index) => (
                    <Badge
                      borderRadius={4}
                      textAlign="center"
                      lineHeight="normal"
                      p={1}
                      px={2}
                      key={index}
                      bg={badgeColor(situation.name)?.bgColor || 'black'}
                      color={badgeColor(situation.name)?.color || 'white'}
                    >
                      {situation.name}
                    </Badge>
                  ))}
                </Flex>
              </Stack>
            </AccordionTemplate>
            {data?.publications && (
              <AccordionTemplate
                border="1px solid"
                borderColor="primaryColor"
                borderRadius={6}
                title="EDITAIS E PUBLICAÇÕES"
              >
                <Tabs size="md" variant="enclosed">
                  <TabList borderColor="#74808b">
                    {data?.publications?.notices.length > 0 && <Tab fontWeight="500">Editais e aditivos</Tab>}
                    {data?.publications?.publications.length > 0 && <Tab fontWeight="500">Publicações</Tab>}
                  </TabList>

                  <TabPanels>
                    {data?.publications?.notices.length > 0 && (
                      <TabPanel>
                        <UnorderedList listStyleType={'none'}>
                          {data?.publications.notices.map((notice, index) => (
                            <>
                              <ListItem
                                mt={2}
                                pb={2}
                                key={index}
                                display={'flex'}
                                flexDir="row"
                                gap={2}
                                alignItems={'center'}
                              >
                                <IoMdLink size={20} color="#0064de" />
                                <Link href={notice?.url || notice?.file} color="colorLink" target="_blank">
                                  {notice.subtitle}{' '}
                                </Link>
                                <TextXS ml={2} as="span">
                                  ({notice?.created_at || ''})
                                </TextXS>
                              </ListItem>
                              <Divider borderColor="lightgray" />
                            </>
                          ))}
                        </UnorderedList>
                      </TabPanel>
                    )}
                    {data?.publications?.publications.length > 0 && (
                      <TabPanel>
                        <UnorderedList listStyleType={'none'}>
                          {data?.publications?.publications?.map((publication, index) => (
                            <>
                              <ListItem
                                mt={2}
                                pb={2}
                                key={index}
                                display={'flex'}
                                flexDir="row"
                                gap={2}
                                alignItems={'center'}
                              >
                                <IoMdLink size={20} color="#0064de" />
                                <Link color="colorLink" href={publication?.file} target="_blank">
                                  {publication?.subtitle}
                                </Link>
                                <TextXS ml={2} as="span">
                                  ({publication?.created_at || ''})
                                </TextXS>
                              </ListItem>
                              <Divider borderColor="lightgray" />
                            </>
                          ))}
                        </UnorderedList>
                      </TabPanel>
                    )}
                  </TabPanels>
                </Tabs>
              </AccordionTemplate>
            )}
            {data && data?.schedules?.length > 0 && (
              <AccordionTemplate border="1px solid" borderColor="primaryColor" borderRadius={6} title="CRONOGRAMA">
                <TableContainer w="100%" mt={4} alignItems="center" maxH="200px" overflowY="auto">
                  <Table variant={'striped'} colorScheme="blackAlpha" w={'100%'}>
                    <Tbody>
                      {data?.schedules.map((schedule, index) => (
                        <Tr key={index}>
                          <Td whiteSpace="wrap">{schedule.event_name}</Td>
                          <Td whiteSpace="wrap">{schedule.period}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </AccordionTemplate>
            )}
            {data?.vacancies && (
              <AccordionTemplate
                border="1px solid"
                borderColor="primaryColor"
                borderRadius={6}
                title={data?.is_cultural_house ? 'TURMAS' : 'VAGAS / CARGOS'}
              >
                {data?.vacancy_observations && (
                  <MessageForUser mb={2} informationText={data?.vacancy_observations || ''} />
                )}

                {data.vacancies?.length > 0 && (
                  <Stack w="100%" mt={4} alignItems="center" maxH="600px" overflow="auto">
                    <Table overflow="auto" variant="striped" colorScheme="blackAlpha">
                      <Thead bg="gray.300" position="sticky" top={0}>
                        <Tr>
                          <Th color={'black'} textAlign="center">
                            <TextS>
                              <strong>DESCRIÇÃO</strong>
                            </TextS>
                          </Th>
                          <Th color={'black'} textAlign="center">
                            <TextS>
                              <strong>{(screenSize === 'base' && 'QTD') || 'Quantidade'}</strong>
                            </TextS>
                          </Th>
                          <Th color={'black'} textAlign="center">
                            <TextS>
                              <strong>AÇÕES</strong>
                            </TextS>
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {data.vacancies.map((vacancie, index) => (
                          <Tr key={index}>
                            <Td textAlign="center">
                              <TextXS>{vacancie?.name}</TextXS>
                            </Td>
                            <Td textAlign="center">
                              <TextXS>{vacancie?.number_of_vacancies}</TextXS>
                            </Td>
                            <Td textAlign="center">
                              <TextXS onClick={() => handleDetail(vacancie)} color="colorLink" cursor="pointer" ml={2}>
                                Detalhes
                              </TextXS>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </Stack>
                )}
              </AccordionTemplate>
            )}
          </Accordion>
        </Stack>

        <Stack mb={10} alignItems="flex-end">
          <Flex gap={'200px'}>
            <Button
              color="white"
              bg="colorLink"
              w="150px"
              onClick={() => {
                navigate(-1);
              }}
              _hover={{
                bg: '#5389ca',
              }}
            >
              Voltar
            </Button>
          </Flex>
        </Stack>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent pb={10} maxW={['80%', '80%', '80%', '40%', '40%']} maxH="none">
          <ModalHeader textAlign="center">Detalhes da vaga</ModalHeader>
          <ModalCloseButton />

          {selectedVacancy && (
            <ModalBody display="flex" flexDir="column" gap={1}>
              <Stack gap={6}>
                <Stack>
                  <TextXS>
                    <strong>Nome: </strong> {selectedVacancy?.name}
                  </TextXS>
                  <TextXS>
                    <strong>Valor da inscrição: </strong> R$ {currencyFormatter(selectedVacancy?.value)}
                  </TextXS>

                  {selectedVacancy?.details && (
                    <TextXS>
                      <strong>Detalhes: </strong> {selectedVacancy?.details}
                    </TextXS>
                  )}
                </Stack>
                <Stack>
                  {selectedVacancy?.has_cr && (
                    <TextXS>
                      <strong>Quantidade de cadastro de reserva: </strong> {selectedVacancy?.number_of_cr_vacancies}
                    </TextXS>
                  )}

                  {selectedVacancy?.has_pcd && (
                    <TextXS>
                      <strong>Quantidade de vagas para cotas PCD - Pessoa com deficiência: </strong>{' '}
                      {selectedVacancy?.number_of_pcd_vacancies}
                    </TextXS>
                  )}

                  {selectedVacancy?.has_black_or_brown && (
                    <TextXS>
                      <strong>Quantidade de vagas para cotas raciais </strong>{' '}
                      {selectedVacancy?.number_of_black_or_brown_vacancies}
                    </TextXS>
                  )}
                </Stack>
              </Stack>
            </ModalBody>
          )}
        </ModalContent>
      </Modal>
    </MainCard>
  );
};

export default SelectiveProcessInformation;
