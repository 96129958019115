import { IDateType } from '../Types';

export interface IDetailSelectiveProcess {
  id: string;
  name: string;
  selective_process_type: string;
  registration_period: string;
  online_registration: boolean;
  general_info: IGeneralInfo;
  publications: IPublicationsProps;
  schedules: ISchedulesProps[];
  vacancies: IVacanciesProps[];
  terms_of_use: string;
  display_terms_acceptance_before_registration: boolean;
  is_cultural_house: boolean;
  details: string;
  vacancy_observations: string;
}

export interface IPublicationsProps {
  notices: IResponsePublication[];
  publications: IResponsePublication[];
}

export interface IResponsePublication {
  url: string;
  file: string;
  type: string;
  subtitle: string;
  created_at: string;
}

export interface ISchedulesProps {
  id: string;
  event_name: string;
  period: string;
}

export interface IVacanciesProps {
  id: string;
  code: string;
  name: string;
  value: string;
  online_registration: boolean;
  workload: string;
  details: string;
  has_cr: boolean;
  limit_of_registration: number;
  salary: string;
  has_pcd: boolean;
  has_black_or_brown: boolean;
  has_special_vacancies: boolean;
  number_of_vacancies: number;
  number_of_cr_vacancies: number;
  number_of_pcd_vacancies: number;
  number_of_black_or_brown_vacancies: number;
  is_level_test: boolean;
  is_initial_semester: boolean;
  is_active: boolean;
  created_at: IDateType;
  level: ILevel;
  cultural_house: boolean;
}

interface ILevel {
  id: string;
  name: string;
}

export interface IGeneralInfo {
  has_pcd: boolean;
  has_black_or_brown: boolean;
  status: IStatusProps[];
  impugnation: boolean;
  notice_number: string;
  exemption_period: string;
  open_exemption: boolean;
  open_registration: boolean;
  open_impugnation: boolean;
  exemption_registration_creation_type: ETypeExemption;
}

export enum ETypeExemption {
  standby = 'standby',
  unified = 'unified',
}

export interface IStatusProps {
  id: string;
  name: string;
}
