import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import React, { memo, ReactNode } from 'react';
import logoUFC from '../../assets/LOGOUFC.png';
import logoFCPC from '../../assets/logoFCPCBRANCO.png';
import { IProofLocationProps } from '../../services/hooks/ProofLocation/useGetProofLocation';

interface IOwnProps {
  data?: IProofLocationProps;
}

const ProofLocation: React.FC<IOwnProps> = ({ data }: IOwnProps) => {
  const formatStringStyle = (style: string) => {
    const stringSplitted = style.split(';').slice(0, -1);
    let styleObject = {};

    stringSplitted.map((str) => {
      const pointSplit = str.split(':');
      const labelStyle = pointSplit[0].split('-');
      let formatLabelStyle: string;
      if (labelStyle.length > 1) {
        formatLabelStyle = `${labelStyle[0]}${labelStyle[1].charAt(0).toUpperCase()}${labelStyle[1]?.slice(1)} `;
      } else {
        formatLabelStyle = labelStyle[0];
      }
      styleObject = { ...styleObject, [formatLabelStyle.trim()]: pointSplit[1].trim() };
    });

    return styleObject;
  };

  const removeHtmlTags = (html: string): ReactNode[] => {
    const parseToHtml = new DOMParser();
    const document = parseToHtml.parseFromString(html, 'text/html');
    function convert(node: ChildNode): ReactNode | string {
      if (node.nodeType === Node.TEXT_NODE) return node.textContent;
      const element = node as HTMLElement;
      const tag = element.tagName.toLowerCase();
      const children = Array.from(node.childNodes).map((child) => convert(child));
      const style = element.getAttribute('style') || '';
      const finalStyle = formatStringStyle(style);
      switch (tag) {
        case 'strong':
          return <Text style={styles.bold}>{children}</Text>;
        case '&nbsp':
          return ' ';
        case 'span': {
          return <Text style={finalStyle}>{children}</Text>;
        }
        case 's':
          return <Text style={{ ...finalStyle, textDecoration: 'line-through' }}>{children}</Text>;
        case 'p': {
          return <Text style={finalStyle}>{children}</Text>;
        }
        case 'em':
          return <Text style={{ ...finalStyle, fontStyle: 'italic' }}>{children}</Text>;
        case 'u': {
          return <Text style={{ ...finalStyle, textDecoration: 'underline' }}>{children}</Text>;
        }
        case 'br':
          break;
        default:
          return <></>;
      }
    }

    const body = document.body;

    return Array.from(body.childNodes).map((child) => convert(child));

    // return html.replace(/<[^>]+>/g, '');
  };

  const styles = StyleSheet.create({
    page: {
      fontFamily: 'Roboto',
      backgroundColor: 'white',
      padding: '50px',
      paddingTop: '30px',
      fontSize: '9px',
    },
    imageHeader: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: '30px',
    },
    header: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '10px',
      maxWidth: '340px',

      fontWeight: 'bold',
    },
    logo: {
      width: '50px',
    },
    candidateData: {
      marginTop: '20px',
      gap: '7px',
    },
    label: {
      fontWeight: 'bold',
      textAlign: 'right',
      width: '130px',
    },
    content: {
      display: 'flex',
      flexDirection: 'row',
      gap: '3px',
    },
    bold: {
      fontWeight: 'bold',
    },
  });

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.imageHeader}>
          <Image src={logoUFC} style={styles.logo} />
          <View style={styles.header}>
            <Text>CARTÃO DE CONVOCAÇÃO DE PROVA</Text>
            <Text>{data?.notice_name}</Text>
          </View>

          <Image src={logoFCPC} style={styles.logo} />
        </View>

        <View>
          <View style={styles.candidateData}>
            <Text style={{ textAlign: 'center', marginBottom: '2px', fontWeight: 'bold' }}>
              DADOS DO(A) CANDIDATO(A)
            </Text>
            <View style={styles.content}>
              <Text style={styles.label}>INSCRIÇÃO: </Text>
              <Text>{data?.registration_code}</Text>
            </View>
            <View style={styles.content}>
              <Text style={styles.label}>CARGO:</Text>
              <Text>{data?.first_vacancy.toUpperCase()}</Text>
            </View>
            {data?.second_vacancy && (
              <View style={styles.content}>
                <Text style={styles.label}>TURMA - SEGUNDA OPÇÃO:</Text>
                <Text>{data?.second_vacancy}</Text>
              </View>
            )}

            <View style={styles.content}>
              <Text style={styles.label}>NOME COMPLETO: </Text>
              <Text>{data?.candidate_name.toUpperCase()}</Text>
            </View>
            <View style={styles.content}>
              <Text style={styles.label}>CPF: </Text>
              <Text>{data?.cpf}</Text>
            </View>
            <View style={styles.content}>
              <Text style={styles.label}>DOCUMENTO: </Text>
              <Text>
                {data?.document_type}: {data?.document_number}
              </Text>
            </View>
            <View style={styles.content}>
              <Text style={styles.label}>DATA DE NASCIMENTO: </Text>
              <Text>{data?.date_of_birth}</Text>
            </View>
            <View style={styles.content}>
              <Text style={styles.label}>ENDEREÇO: </Text>
              <Text>{data?.candidate_address.toUpperCase()}</Text>
            </View>
            <View style={styles.content}>
              <Text style={styles.label}>TELEFONE CELULAR: </Text>
              <Text>{data?.cellphone}</Text>
            </View>
          </View>

          <View style={styles.candidateData}>
            <Text style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '2px' }}>
              DADOS PARA REALIZAÇÃO DA PROVA
            </Text>
            <View style={styles.content}>
              <Text style={styles.label}>LOCAL DE PROVA: </Text>
              <Text>{data?.test_site_name}</Text>
            </View>
            <View style={styles.content}>
              <Text style={styles.label}>SALA: </Text>
              <Text>
                {data?.test_room_name} <Text style={{ fontSize: '7px' }}>({data?.details})</Text>
              </Text>
            </View>
            <View style={styles.content}>
              <Text style={styles.label}>ENDEREÇO: </Text>
              <Text>{data?.test_site_address.toUpperCase()}</Text>
            </View>
            <View style={styles.content}>
              <Text style={styles.label}>DATA DA PROVA: </Text>
              <Text>{data?.test_date}</Text>
            </View>
            <View style={styles.content}>
              <Text style={styles.label}>HORA DA PROVA: </Text>
              <Text>{data?.test_hour}h</Text>
            </View>
            <View style={styles.content}>
              <Text style={styles.label}>ABERTURA DOS PORTÕES:</Text>
              <Text>{data?.gate_opening_time}h</Text>
            </View>
            <View style={styles.content}>
              <Text style={styles.label}>FECHAMENTO DOS PORTÕES: </Text>
              <Text>{data?.gate_closing_time}h</Text>
            </View>
          </View>
          {data?.candidate_registration_card && (
            <View style={styles.candidateData}>
              <Text style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '2px' }}>
                INFORMAÇÕES IMPORTANTES
              </Text>
              <Text>{removeHtmlTags(data?.candidate_registration_card || '')}</Text>
            </View>
          )}
        </View>
      </Page>
    </Document>
  );
};

export default memo(ProofLocation);
